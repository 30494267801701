<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm" >
                <el-form-item label="归属机构" prop="orgId">
                    <template>
                        <el-select v-model="searchForm.orgId" clearable>
                            <el-option v-for="item in orgData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="基站名称" prop="stationName">
                    <el-input v-model="searchForm.stationName" placeholder="基站名称" />
                </el-form-item>
                <el-form-item label="归属地区" prop="areaName">
                    <el-cascader :options="areaOptions" :props="areaProps" clearable v-model="searchForm.areaId"></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTableData">查询</el-button>
                    <el-button type="primary" @click="addStationInfoPage">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="stationNumber" label="基站编号" width="120"/>
                <el-table-column prop="name" label="基站名称" />
                <el-table-column prop="orgName" label="所属机构" width="150"/>
                <el-table-column prop="area.nationCn" label="所属国家" width="120"/>
                <el-table-column prop="area.provCn" label="所属省" width="150"/>
                <el-table-column prop="area.cityCn" label="所属市" width="150"/>
                <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat" width="100" align="center"/>
                <el-table-column label="操作" width="240px" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="editStationInfoPage(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" @click="openAssignUserPage(scope.$index, scope.row)">分配用户</el-button>
                        <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"  type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination background layout="total, prev, pager, next, jumper" :total="tableTotal"
                               @current-change="handleCurrentChange" :current-page.sync="curr"/>
            </div>
            <el-dialog title="新增基站" :visible.sync="addFormVisible" center :close-on-click-modal="false" width="800px" top="5vh">
                <el-form :model="addForm" :rules="formRules" ref="addForm" label-width="100px" :inline="true">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="归属机构" prop="orgId">
                                <template>
                                    <el-select v-model="addForm.orgId" clearable style = "width:201px;">
                                        <el-option v-for="item in orgData" :key="item.id" :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属地区" prop="areaIds">
                                <el-cascader
                                    v-model="addForm.areaIds"
                                    @change="addStationAreaChangeEventHandle"
                                    :options="areaOptions"
                                    :props="{
                                        value: 'areaKey',
                                        label: 'areaName',
                                        children: 'subAreas',
                                        checkStrictly: false
                                    }"
                                    style="width: 201px"
                                    ref="addArea"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="基站编号" prop="stationNumber">
                                <el-input v-model="addForm.stationNumber" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="基站名称" prop="name">
                                <el-input v-model="addForm.name" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="百度经度" prop="baiduLng">
                                <el-input v-model="addForm.baiduLng" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="百度纬度" prop="baiduLat">
                                <el-input v-model="addForm.baiduLat" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="海拔高度" prop="altitude">
                                <el-input v-model="addForm.altitude" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="基站地址" prop="address">
                                <el-input v-model="addForm.address" autocomplete="off"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="负责人" prop="director">
                                <el-input v-model="addForm.director" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="负责人电话" prop="phone">
                                <el-input v-model="addForm.phone" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="基站照片" prop="img">
                                <el-upload
                                        action=""
                                        list-type="picture-card"
                                        :auto-upload="false"
                                        :on-preview="handlePictureCardPreview"
                                        :on-remove="handleRemove"
                                        :on-change="fileChange"
                                        :multiple="true"
                                        :file-list="fileList">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注说明" prop="remark">
                                <el-input v-model="addForm.remark" type="textarea" rows="4" placeholder="请输入内容"
                                          autocomplete="off" class="remark-input-size" style="width: 579px;"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleAdd" :loading="addLoading">确定</el-button>
                    <el-button @click="addFormVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="修改基站" :visible.sync="editFormVisible" v-if="editFormVisible" center :close-on-click-modal="false" width="800px" top="5vh">
                <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="100px" :inline="true">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="归属机构" prop="orgId">
                                <template>
                                    <el-select v-model="editForm.orgId" clearable style = "width:201px;">
                                        <el-option v-for="item in orgData" :key="item.id" :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属地区" prop="areaIds">
                                <el-cascader
                                    v-model="editForm.areaIds"
                                    :options="areaOptions"
                                    @change="editStationAreaChangeEventHandle"
                                    :props="{
                                        value: 'areaKey',
                                        label: 'areaName',
                                        children: 'subAreas',
                                        checkStrictly: false
                                    }"
                                    style="width: 201px"
                                    ref="editArea">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="基站编号" prop="stationNumber">
                                <el-input v-model="editForm.stationNumber" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="基站名称" prop="name">
                                <el-input v-model="editForm.name" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="百度经度" prop="baiduLng">
                                <el-input v-model="editForm.baiduLng" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="百度纬度" prop="baiduLat">
                                <el-input v-model="editForm.baiduLat" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="海拔高度" prop="altitude">
                                <el-input v-model="editForm.altitude" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="基站地址" prop="address">
                                <el-input v-model="editForm.address" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="负责人" prop="director">
                                <el-input v-model="editForm.director" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="负责人电话" prop="phone">
                                <el-input v-model="editForm.phone" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="基站照片" prop="img">
                                <el-upload
                                        action=""
                                        list-type="picture-card"
                                        :auto-upload="false"
                                        :on-preview="handlePictureCardPreview"
                                        :on-remove="handleRemove"
                                        :on-change="fileChange"
                                        :multiple="true"
                                        :file-list="fileList">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注说明" prop="remark">
                                <el-input v-model="editForm.remark" type="textarea" rows="4" placeholder="请输入内容"
                                          autocomplete="off" class="remark-input-size"style="width: 579px;"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleEdit" :loading="editLoading">确定</el-button>
                    <el-button @click="editFormVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="分配用户" :visible.sync="assignPageInfo.assignUserPageVisible" custom-class="dialog-assign-user" top="5vh" center width="1200px"
                       :close-on-click-modal="false">
                <el-form :inline="true" :model="assignPageInfo.assignUserQueryForm" >
                    <el-form-item label="登录名" prop="loginName">
                        <el-input v-model="assignPageInfo.assignUserQueryForm.loginName" placeholder="请输入登录名" />
                    </el-form-item>
                    <el-form-item label="用户名" prop="userName">
                        <el-input v-model="assignPageInfo.assignUserQueryForm.userName" placeholder="请输入用户名" />
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="assignPageInfo.assignUserQueryForm.email" placeholder="请输入邮箱" />
                    </el-form-item>
                    <el-form-item label="电话" prop="phone">
                        <el-input v-model="assignPageInfo.assignUserQueryForm.phone" placeholder="请输入电话" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="paramQueryStationAssignUserList">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="assignPageInfo.tableData" border size="small" stripe style="width: 100%;height: 550px"
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="assignPageInfo.dataLoading">
                    <el-table-column prop="orgName" label="归属机构" />
                    <el-table-column prop="loginName" label="登录名" />
                    <el-table-column prop="userName" label="用户名" />
                    <el-table-column prop="email" label="邮箱" />
                    <el-table-column prop="phone" label="电话" width="100" />
                    <el-table-column prop="isAssign" label="是否分配" width="80" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isAssign=='0'">未分配</div>
                            <div v-else>已分配</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isAssign=='1'">
                                <el-button size="mini" type="primary" disabled>分配</el-button>
                                <el-button size="mini" @click="cancelAssignUser(scope.$index, scope.row)" type="danger">取消</el-button>
                            </div>
                            <div v-else>
                                <el-button size="mini" @click="assignUser(scope.$index, scope.row)" type="primary">分配</el-button>
                                <el-button size="mini" type="danger" disabled>取消</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top: 15px;text-align: right;">
                    <el-pagination background layout="total, prev, pager, next, jumper" :total="assignPageInfo.tableTotal"
                                   @current-change="assignUserTableCurChange" :current-page.sync="assignPageInfo.curr"/>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import NavBreadcrumb from '../../components/NavBreadcrumb';
import axios from 'axios';
import global from '../../components/Global';
import {
    deleteStationInfo,
    queryAllAreaInfo,
    queryOrgInfo,
    queryStationAssignUserList,
    queryStationInfo,
    stationAssignUser,
    stationCancelAssignUser
} from "@/api/station/stationInfoApi";

export default {
        name: "StationInfoManage",
        components: {

            NavBreadcrumb
        },
        data() {

            // 手机号自定义校验
            let phone = (rule, value, callback) => {

                if (!value) {

                    callback(new Error('电话不能为空'));
                } else {

                    let tel = /^0\d{2,3}-?\d{7,8}$/;
                    let phoneIg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1})|(17[0-9]{1}))+\d{8})$/;
                    if (value.length == 11) {//手机号码

                        if(phoneIg.test(value)) {

                            callback();
                        } else {

                            callback(new Error('电话格式错误'));
                        }
                    } else if(value.length == 13 && value.indexOf("-") != -1) {//电话号码

                        if(tel.test(value)) {

                            callback();;
                        } else {

                            callback(new Error('电话格式错误'));
                        }
                    } else {

                        callback(new Error('电话格式错误'));
                    }
                }
            };
            // 百度经度自定义校验
            let baiduLng = (rule, value, callback) => {

                if (!value) {

                    callback(new Error('经度为必填项'));
                } else {

                    let str = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/;
                    if(str.test(value)) {

                        callback();
                    } else {

                        callback(new Error('经度整数部分为0-180,小数部分为0到15位!'));
                    }
                }
            };
            // 百度纬度自定义校验
            let baiduLat = (rule, value, callback) => {

                if (!value) {

                    callback(new Error('纬度为必填项'));
                } else {

                    let str = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/;
                    if(str.test(value)) {

                        callback();
                    } else {

                        callback(new Error('纬度整数部分为0-90,小数部分为0到15位!'));
                    }
                }
            };
            // 海拔高度自定义校验
            let altitude = (rule, value, callback) => {

                if (!value) {

                    callback(new Error('海拔高度为必填项'));
                } else {

                    let str = /^[1-9][0-9]*([\.][0-9]{1,2})?$/;
                    if(str.test(value)) {

                        callback();
                    } else {

                        callback(new Error('海拔高度必须为整数或两位小数!'));
                    }
                }
            };
            return {

                areaOptions: [],
                areaProps: {

                    value: 'areaKey',
                    label: 'areaName',
                    children: 'subAreas',
                    checkStrictly: true
                },
                modalKey: 0,
                propsEdit:{

                    value: 'areaId',
                    label: 'districtCn',
                    children: 'items',
                    checkStrictly: true
                },
                fileList: [],
                formRules: {

                    orgId: [
                        {required: true, message: '请选择机构', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请输入基站名称', trigger: 'blur'},
                    ],
                    stationNumber : [
                        {required: true, message: '请输入基站编号', trigger: 'blur'},
                    ],
                    address: [
                        {required: true, message: '请输入基站地址', trigger: 'blur'},
                    ],
                    areaId: [
                        {required: true, message: '请选择地区', trigger: 'blur'},
                    ],
                    director: [
                        {required: true, message: '请输入基站负责人', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, trigger: 'blur', validator: phone},
                    ],
                    baiduLng: [
                        {trigger: 'blur', validator: baiduLng },
                    ],
                    baiduLat: [
                        {trigger: 'blur', validator: baiduLat },
                    ],
                    altitude:[
                        {trigger: 'blur', validator: altitude}
                    ]
                },
                formData: new FormData(),
                areaData:[],
                orgData : [],
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                addForm: {

                    orgId:"",
                    areaId: "",
                    stationNumber :"",
                    name: "",
                    baiduLng: "",
                    baiduLat : "",
                    altitude : "",
                    address: "",
                    director: "",
                    phone : "",
                    remark: ""
                },
                editForm: {

                    id: "",
                    orgId:"",
                    areaId: "",
                    areaIds: "",
                    stationNumber :"",
                    name: "",
                    address: "",
                    remark: "",
                    director: "",
                    baiduLng: "",
                    baiduLat : "",
                    altitude: "",
                    phone : "",
                    removeFileList: [],
                },
                listLoading:false,
                searchForm: {

                    orgId: '',
                    areaId: '',
                    stationName: ''
                },
                tableData: [],
                tableTotal: 0,
                curr: 1,
                addFormVisible: false,
                editFormVisible: false,
                addLoading: false,
                editLoading: false,
                imgList : [],
                distrinctKey: 0,
                enableDelFlag: 0,
                assignPageInfo: {

                    dataLoading: false,
                    assignUserPageVisible: false,
                    assignUserQueryForm: {

                        size: 10,
                        stationId: '',
                        stationOrgId: '',
                        loginName: '',
                        userName: '',
                        email: '',
                        phone: ''
                    },
                    tableData: [],
                    tableTotal: 0,
                    curr: 1,
                }
            }
        },
        methods: {
            getOrgData() {

                queryOrgInfo({}).then((res) => {

                    this.orgData = res.data.list;
                }).catch(err => err);
            },
            dateFormat(row, column, cellValue) {

                let date = new Date(cellValue);
                return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            },
            getTreeData(data) {

                for (let i = 0;i < data.length; i++) {

                    if (data[i].items.length < 1) {

                        data[i].items = undefined;
                    } else {

                        // 判断返回查到的地区信息是否为空
                        this.getTreeData(data[i].items);
                    }
                }
                return data;
            },
            getAreaData() {

                queryAllAreaInfo({}).then((res) => {

                    this.areaOptions = res.data;
                });
            },
            addStationInfoPage() {

                this.imgList = [];
                this.fileList = [];
                this.addFormVisible = true;
            },
            editStationInfoPage(index, row) {

                let params = {

                    id : row.id
                };
                this.editFormVisible = true;
                this.imgList = [];
                this.fileList = [];
                queryStationInfo(params).then((res) => {

                    let areaIds;
                    if (res.data.list[0].area.nationEn === 'china') {

                        areaIds = [

                            res.data.list[0].area.nationEn,
                            res.data.list[0].area.provEn,
                            res.data.list[0].area.cityEn
                        ];
                    } else {

                        areaIds = [

                            res.data.list[0].area.nationEn,
                            res.data.list[0].area.provEn
                        ];
                    }
                    this.editForm.id = res.data.list[0].id;
                    this.editForm.orgId = res.data.list[0].orgId;
                    this.editForm.areaIds = areaIds;
                    this.editForm.areaId = res.data.list[0].areaId;
                    this.editForm.stationNumber = res.data.list[0].stationNumber;
                    this.editForm.name = res.data.list[0].name;
                    this.editForm.baiduLng = res.data.list[0].baiduLng;
                    this.editForm.baiduLat = res.data.list[0].baiduLat;
                    this.editForm.altitude = res.data.list[0].altitude;
                    this.editForm.address = res.data.list[0].address;
                    this.editForm.director = res.data.list[0].director;
                    this.editForm.phone = res.data.list[0].phone;
                    this.editForm.remark = res.data.list[0].remark;
                    let imageList = res.data.list[0].imgList;
                    for (let i = 0; i < imageList.length; i++) {

                        let img = {

                            name: i,
                            url: imageList[i]
                        }
                        this.fileList.push(img);
                    }
                    this.distrinctKey = new Date().getSeconds();
                });
            },
            queryTableData() {

                this.curr = 1;
                this.getTableData();
            },
            handleCurrentChange(val) {

                this.curr = val;
                this.getTableData();
            },
            getTableData() {

                let params = {

                    curr: this.curr,
                    size: 10,
                    orgId: this.searchForm.orgId,
                    stationName: this.searchForm.stationName,
                };
                if (this.searchForm.areaId.length === 1) {

                    params.nationEn = this.searchForm.areaId[0];
                } else if (this.searchForm.areaId.length === 2) {

                    params.nationEn = this.searchForm.areaId[0];
                    params.provEn = this.searchForm.areaId[1];
                } else if (this.searchForm.areaId.length === 3) {

                    params.nationEn = this.searchForm.areaId[0];
                    params.provEn = this.searchForm.areaId[1];
                    params.cityEn = this.searchForm.areaId[2];
                }
                this.listLoading = true;
                queryStationInfo(params).then((res) => {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.list;
                    this.listLoading = false;
                }).catch(err => err);
            },
            handleAdd() {

                this.$refs["addForm"].validate((valid) => {

                    if (valid) {

                        this.addLoading = true;
                        let formData = new FormData();
                        if (this.fileList) {

                            this.fileList.forEach((file) => {

                                formData.append('files', file.raw);
                            });
                        }
                        formData.append("orgId", this.addForm.orgId);
                        formData.append("areaId", this.addForm.areaId);
                        formData.append("stationNumber", this.addForm.stationNumber);
                        formData.append("name", this.addForm.name);
                        formData.append("baiduLng", this.addForm.baiduLng);
                        formData.append("baiduLat", this.addForm.baiduLat);
                        formData.append("altitude", this.addForm.altitude);
                        formData.append("address", this.addForm.address);
                        formData.append("director", this.addForm.director);
                        formData.append("phone", this.addForm.phone);
                        formData.append("remark", this.addForm.remark);
                        axios({

                            url: global.serverBase + '/stationInfoManage/createStationInfo',
                            method: 'post',
                            data: formData
                        }).then(res => {

                            if (res.code === global.response_status_success_obj) {

                                this.addLoading = false;
                                this.addFormVisible = false;
                                this.$message.success("电站创建成功");
                                this.getTableData();
                            } else {

                                this.addLoading = false;
                                this.$message.error(res.msg);
                            }
                        },err => {

                            this.addLoading = false;
                            this.$message.error(res.msg);
                        });
                    } else {

                        return false;
                    }
                });
            },
            handleEdit() {

                this.$refs["editForm"].validate((valid) => {

                    if (valid) {

                        this.editLoading = true;
                        let formData = new FormData();
                        if (this.fileList) {

                            this.fileList.forEach((file) => {

                                if (file.status === 'ready') {

                                    formData.append("files", file.raw);
                                }
                            });
                        }
                        formData.append("id", this.editForm.id);
                        formData.append("orgId", this.editForm.orgId);
                        formData.append("areaId", this.editForm.areaId);
                        formData.append("stationNumber", this.editForm.stationNumber);
                        formData.append("name", this.editForm.name);
                        formData.append("baiduLng", this.editForm.baiduLng);
                        formData.append("baiduLat", this.editForm.baiduLat);
                        formData.append("altitude", this.editForm.altitude);
                        formData.append("address", this.editForm.address);
                        formData.append("director", this.editForm.director);
                        formData.append("phone", this.editForm.phone);
                        formData.append("remark", this.editForm.remark);
                        formData.append('imgList', this.editForm.removeFileList);


                        axios({

                            url: global.serverBase + '/stationInfoManage/updateStationInfo',
                            method: 'post',
                            data: formData
                        }).then(res => {

                            if (res.code === global.response_status_success_obj) {

                                this.editLoading = false;
                                this.editFormVisible = false;
                                this.$message.success("电站编辑成功");
                                this.getTableData();
                            } else {

                                this.editLoading = false;
                                this.$message.error(res.msg);
                            }
                        },err => {

                            this.editLoading = false;
                            this.$message.error(res.msg);
                        });
                    } else {

                        return false;
                    }
                });
            },
            handleDelete(index, row) {

                let that = this;
                this.enableDelFlag = 0;
                let params = {

                    stationId: row.id
                }
                that.$confirm('是否需要删除？', '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {

                    let params = {

                        id : row.id
                    };
                    deleteStationInfo(params).then((res) => {

                        if (res.code == 500) {

                            that.$message.error(res.msg);
                        } else {

                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                        }
                        that.getTableData();
                    });
                });
            },
            // 删除图片
            handleRemove(file, fileList) {

                if (file.status === 'success') {

                    this.editForm.removeFileList.push(file.url);
                }
            },
            // 图片发生改变
            fileChange(file, fileList){

                this.fileList = fileList;
            },
            // 显示图片
            handlePictureCardPreview(file) {

                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            addStationAreaChangeEventHandle(value) {

                let ob = this.$refs.addArea.getCheckedNodes();
                this.addForm.areaId = ob[0].data.id;
            },
            editStationAreaChangeEventHandle() {

                let ob = this.$refs.editArea.getCheckedNodes();
                this.editForm.areaId = ob[0].data.id;
            },
            // 打开分配用户页面
            openAssignUserPage(index, row) {

                this.assignPageInfo.curr = 1;
                this.assignPageInfo.assignUserPageVisible = true;
                this.assignPageInfo.assignUserQueryForm.stationId = row.id;
                this.assignPageInfo.assignUserQueryForm.stationOrgId = row.orgId;
                this.queryStationAssignUserList();
            },
            // 条件查询电站分配用户列表
            paramQueryStationAssignUserList() {

                this.assignPageInfo.curr = 1;
                this.queryStationAssignUserList();
            },
            // 查询电站分配用户列表
            queryStationAssignUserList() {

                this.assignPageInfo.dataLoading = true;
                let param = this.assignPageInfo.assignUserQueryForm;
                param.curr = this.assignPageInfo.curr;
                queryStationAssignUserList(param).then((res) => {

                    this.addLoading = false;
                    if (res.code === "200") {

                        this.assignPageInfo.dataLoading = false;
                        this.assignPageInfo.tableData = res.data.listResult;
                        this.assignPageInfo.tableTotal = res.data.totalNum;
                    } else {

                        this.$message.error({
                            message: "查询可分配用户列表失败",
                            center: true
                        });
                    }
                });
            },
            // 分配用户
            assignUser(index, row) {

                let param = {
                    userId: row.userId,
                    stationId: this.assignPageInfo.assignUserQueryForm.stationId
                };
                stationAssignUser(param).then((res) => {

                    if (res.code === "100") {

                        this.queryStationAssignUserList();
                    } else {

                        this.$message.error({
                            message: "分配用户失败",
                            center: true
                        });
                    }
                }).catch(err => err);
            },
            // 取消分配用户
            cancelAssignUser(index, row) {

                let param = {
                    userId: row.userId,
                    stationId: this.assignPageInfo.assignUserQueryForm.stationId
                };
                stationCancelAssignUser(param).then((res) => {

                    if (res.code === "100") {

                        this.queryStationAssignUserList();
                    } else {

                        this.$message.error({
                            message: "取消分配用户失败",
                            center: true
                        });
                    }
                }).catch(err => err);
            },
            // 分配用户页面分页事件
            assignUserTableCurChange(val) {

                this.assignPageInfo.curr = val;
                this.queryStationAssignUserList();
            }
        },
        mounted() {

            this.getOrgData();
            this.queryTableData();
            this.getAreaData();
        }
    }
</script>

<style scoped>
    .dialog-assign-user {

        width: 1200px;
        height: 800px;
    }
</style>
